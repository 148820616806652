<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="bg-gray-3">
		
		<a-row :gutter="24" class="mt-0 pt-0">

			<a-col :span="24" :md="24" :lg="24" :xl="24" class="pt-0 mt-0">

				

                    <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 70px;">
						
						<a-col :span="24" :md="18" :lg="18" :xl="18" class="col-form pt-20 mt-10 px-0">

                            <a-card :bordered="false" class="px-0 mx-0 mt-0 pt-0" style="padding-top: 0px !important">

                                <a-row>
                                    <a-col :span="24" class="px-20 text-justify">

                                        <h2 class="pt-2"><strong>Privacy Policy</strong></h2>

                                        <p class="text-justify">
                                            We, Silabu respect your privacy and are committed to protecting your information.
                                            This privacy policy (the Policy) applies to your use of the Platforms (website). The
                                            Policy outlines the information (as defined below)
                                        </p>
                                    

                                                <p class="text-justify">
                                                    We collect and use information only to allow us to provide our services to our
                                                    customers and end users and we do so at the direction of our customers.  We do not
                                                    and will not sell or rent your data to third parties unless this is required in the context
                                                    of changes to our business structure such as a merger or acquisition.
                                                </p>

                                                <h5 class="pt-3"><strong>Children’s Privacy</strong></h5>

                                                <p class="text-justify">
                                                    We do not knowingly collect any information from children under the age of 18
                                                    unless and until the relevant institution has provided consent and authorization for a
                                                    student under 18 to use our services and for us to collect information from such
                                                    student.
                                                </p>


                                                <h5 class="pt-3"><strong>Types of Data Collected</strong></h5>

                                                <p class="text-justify">
                                                    Data collected by Silabu may include personally identifiable information. Among the
                                                    types of Personal data that this platform collects, by itself or through third parties,
                                                    Cookies; Usage Data, first name, last name, email address, phone number, National
                                                    Identification number, payment details etc.
                                                </p>


                                                <h5 class="pt-3"><strong>How we collect information</strong></h5>

                                                <p class="text-justify">
                                                    Personal Data is freely provided by the User(s), or, in case of Usage Data, collected
                                                    automatically when using this Application. Unless specified otherwise, all Data
                                                    requested by this platform is mandatory and failure to provide this data may make it
                                                    impossible for this platform to provide its services. In cases where this platform
                                                    specifically states that some Data is not mandatory, Users are free not to feed this
                                                    Data without consequences to the availability or the functioning of the Service.
                                                </p>

                                                <p class="text-justify">
                                                    Users who are uncertain about which Personal Data is mandatory are welcome to
                                                    contact Silabu. Any use of Cookies – or of other tracking tools – by this Application
                                                    or by the owners of third-party services used by this Application serves the purpose
                                                    of providing the Service required by the User, in addition to any other purposes
                                                    described in the present document and in the Cookie Policy, if available.
                                                </p>

                                                <p class="text-justify">
                                                    Users are responsible for any third-party Personal Data obtained, published or
                                                    shared through this Application and confirm that they have the third party’s consent
                                                    to provide the Data to the Owner.
                                                </p>


                                                <h5 class="pt-3"><strong>Failing to provide information</strong></h5>

                                                <p class="text-justify">
                                                    Where we need to collect Information by law, or under the terms of a contract,
                                                    engagement we have with you, and you fail to provide that data when requested, we
                                                    may not be able to provide the service or conclude an agreement with you. In this
                                                    case, we may have to cancel a service you have with us but we will notify you if this
                                                    is the case at the time.
                                                </p>


                                                <h5 class="pt-3"><strong>How we collect information</strong></h5>

                                                <p class="text-justify">
                                                    We use different methods to collect data from and about you including through:
                                                </p>

                                                <ol type="a">
                                                    <li>
                                                        <p>
                                                            <b>Direct interactions: </b>where you may give us your Identity, Contact and financial
                                                            Information by filling in forms and /or communicating with us by post, email,
                                                            phone or through the Platforms or otherwise. This includes Personal Information
                                                            you provide when you create an account on our Platforms and subscribe to our
                                                            services; request marketing to be sent to you; enter a competition, promotion or
                                                            survey; or give us feedback or contact us.
                                                        </p>
                                                    </li>

                                                    <li>
                                                        <p>
                                                            <b>Automated technologies or interactions: </b>as you interact with the Platform, we
                                                            will automatically collect technical data about your equipment, browsing actions
                                                            and patterns. We collect this Information by using cookies, server logs, web
                                                            beacons and other similar technologies
                                                        </p>
                                                    </li>

                                                    <li>
                                                        <p>
                                                            <b>Third parties or publicly available sources: </b>where we receive your
                                                            Information from third parties and public sources including technical information
                                                            from analytics, advertising networks, and search information providers; contact,
                                                            financial and transaction information from providers of technical, payment and
                                                            delivery services; identity and contact information from data brokers or
                                                            aggregators; and identity, verification and contact information from publicly
                                                            available sources.
                                                        </p>
                                                    </li>

                                                </ol>


                                                <h5 class="pt-3"><strong>How we use your data.</strong></h5>

                                                <p class="text-justify">
                                                    We will only use your Information when the law allows us to do so and:
                                                </p>

                                                <ol type="a">
                                                    <li>
                                                        <p>
                                                            To provide you with the services we offer in accordance with any agreement we
                                                            have with you or are trying to enter with you;
                                                        </p>
                                                    </li>

                                                    <li>
                                                        <p>
                                                            To contact you about our services, events and promotions;
                                                        </p>
                                                    </li>

                                                    <li>
                                                        <p>
                                                            To comply with legal obligations;
                                                        </p>
                                                    </li>

                                                    <li>
                                                        <p>
                                                            To perform a task in the consideration of the greater public interest;
                                                        </p>
                                                    </li>

                                                    <li>
                                                        <p>
                                                            Where necessary for our legitimate interests (or those of a third party) and your
                                                            interests and fundamental rights do not override those interests; and/or
                                                        </p>
                                                    </li>

                                                    <li>
                                                        <p>
                                                            Where Information is required for statistical or research purposes;
                                                        </p>
                                                    </li>


                                                </ol>


                                                <p>
                                                    Please note that we may process your Information for more than one lawful ground
                                                    depending on the specific purpose for which we are using your data.
                                                </p>


                                                <h5 class="pt-3"><strong>Accuracy of the data you provide us.</strong></h5>

                                                <p class="text-justify">
                                                    It is important that the Information we hold is accurate and current to enable us to
                                                    deliver our services. As such, please keep us informed if your Information or any
                                                    other information changes in the course of your use of the Platform and our services.
                                                    
                                                    You guarantee and declare that you are the owner of the information you submit on
                                                    the Platform or share with us, or that you have necessary rights to share such
                                                    information with us. You also guarantee and declare that the information is exact,
                                                    true and verified, and the use of the information is not contrary to this Policy or
                                                    applicable laws and will not damage a third party’s reputation. You accept full and
                                                    complete liability for any information that is contrary to this Policy or any applicable
                                                    laws.
                                                </p>


                                                <h5 class="pt-3"><strong>Security of the data</strong></h5>

                                                <p class="text-justify">
                                                    The security and integrity of your Information is important to us. We have in place
                                                    appropriate security measures and take all due care to prevent your Information from
                                                    being accidentally lost, used, or accessed in an unauthorized way, altered or
                                                    disclosed. Unfortunately, we cannot guarantee its absolute security since there is no
                                                    method of transmission over the internet or method of electronic storage that is fully
                                                    secure. We disclaim all liability to you to the greatest extent possible under the law
                                                    should such a breach of security occur.
                                                </p>


                                                <p class="text-justify">
                                                    Where there is unauthorized disclosure or access to your Information and we are of
                                                    the opinion there is a real risk to you, we shall notify you of the security breach within
                                                    a reasonable period except for where notification may prejudice any investigation
                                                    into the breach or our efforts to prevent or detect such breaches.
                                                </p>

                                                <p class="text-justify">
                                                    As a user of our Platform, you are entirely responsible for maintaining the
                                                    confidentiality of your password and login name. Furthermore, you are entirely
                                                    responsible for all activities that occur under your user account. You must
                                                    immediately notify us of any unauthorized use of your login details or any other
                                                    breach of security known to you.
                                                </p>


                                                <h5 class="pt-3"><strong>Sharing your information</strong></h5>

                                                <p class="text-justify">
                                                    We respect your privacy and, except as specified in this Policy, we will not disclose
                                                    your Information to third parties without your consent.
                                                </p>

                                                <p class="text-justify">
                                                    To enable us provide our services to you, we may share your Information with:   
                                                </p>

                                                <ol type="a">
                                                    <li>
                                                        <p>
                                                            Internal third parties such as companies which are affiliated to us;
                                                        </p>
                                                    </li>

                                                    <li>
                                                        <p>
                                                            External third parties central to the provision of the services offered to you by
                                                            us;
                                                        </p>
                                                    </li>

                                                    <li>
                                                        <p>
                                                            External third parties such as governmental bodies should we be required to
                                                            do so;
                                                        </p>
                                                    </li>

                                                    <li>
                                                        <p>
                                                            Third parties who help us use your Information;
                                                        </p>
                                                    </li>

                                                    <li>
                                                        <p>
                                                            Third parties to whom we may choose to sell, transfer or merge parts of our
                                                            business or our assets. Alternatively, we may seek to acquire other
                                                            businesses or merge with them. If a change happens to our business, then
                                                            the new owners may use your Information in the same way as set out in this
                                                            Policy.
                                                        </p>
                                                    </li>

                                                </ol>


                                                <p class="text-justify">
                                                    We require all third parties to respect the security of your Information and to treat it in
                                                    accordance with the law. We do not allow our third-party service providers to use
                                                    your Information for their own purposes and only permit them to process your
                                                    Information for specified purposes and in accordance with our instructions.
                                                </p>


                                                <p class="text-justify">
                                                    We will also disclose information, including Information, to third parties:
                                                </p>


                                                <ol type="a">
                                                    <li>
                                                        <p>
                                                            When we are required to do so by a court order or by any regulatory authority;
                                                        </p>
                                                    </li>

                                                    <li>
                                                        <p>
                                                            Where we are required to do so in order to comply with our legal and
                                                            regulatory obligations in terms of any legislation or regulation applicable to us
                                                            and our business (including fraud prevention and anti-money laundering);
                                                        </p>
                                                    </li>

                                                    <li>
                                                        <p>
                                                            in order to exercise, protect and defend our rights and property (including our
                                                            intellectual property); or
                                                        </p>
                                                    </li>

                                                    <li>
                                                        <p>
                                                            Where such disclosure is in the public interest.
                                                        </p>
                                                    </li>

                                                </ol>


                                                <h5 class="pt-3"><strong>Retention of data</strong></h5>

                                                <p class="text-justify">
                                                    We strive to give you control over certain Information uses, especially around
                                                    marketing and advertising. We will only send marketing communications to you if you
                                                    have requested the information or have obtained Services from us and have not
                                                    opted out of receiving that marketing.
                                                </p>

                                                <p class="text-justify">
                                                    You can request us to stop sending you marketing messages at any time by
                                                    following the opt-out links on any marketing message sent to you or by contacting us
                                                    at any time. When you opt out of receiving the marketing messages, the opt-out will
                                                    not apply to information provided to us because of a service experience or other
                                                    transactions.
                                                </p>

                                                <p class="text-justify">
                                                    Records that you have shared publicly or with another User (e.g., reviews, messages 
                                                    you send and bookings you make) may remain on the app after your personal 
                                                    information has been deleted but will no longer have identifying information which 
                                                    links them to you attached.
                                                </p>

                                                <p class="text-justify">
                                                    We may retain records where necessary to fulfil our regulatory or statutory duties.
                                                </p>


                                                <h5 class="pt-3"><strong>Third-party links</strong></h5>

                                                <p class="text-justify">
                                                    The Platform may include links to third-party websites, plug-ins and applications.
                                                    Clicking on those links or enabling those connections may allow third parties to
                                                    collect or share data about you. We do not control these third-party websites, plug-
                                                    ins and applications, and are not responsible for their privacy statements.
                                                </p>


                                                <h5 class="pt-3"><strong>Changes to the privacy Policy</strong></h5>

                                                <p class="text-justify">
                                                    We regularly review and update this Policy in order to reflect changes to our
                                                    practices, for operational, legal or regulatory reasons, and for any other purpose.
                                                    Any revisions to this Policy will be uploaded to the Platform and such upload will be
                                                    deemed sufficient communication to you.
                                                </p>


                                                <h5 class="pt-3"><strong>Cookies Policy</strong></h5>

                                                <p class="text-justify">
                                                    A cookie is a small file with information that your browser stores on your device.
                                                    Information in this file is typically shared with the owner of the platform in addition to
                                                    potential third parties. The information collected may be used in our platform function
                                                    and/or to improve your experience.
                                                </p>


                                                <h5 class="pt-3"><strong>How we use cookies</strong></h5>

                                                <p class="text-justify">
                                                    To give you the best experience possible, we use the following types of cookies:
                                                </p>


                                                <p class="text-justify pl-3">
                                                    <b>Strictly Necessary: </b>As a web application, we require certain necessary cookies
                                                    to run our service.
                                                </p>

                                                <p class="text-justify pl-3">
                                                    <b>Preference: </b>We use preference cookies to help us remember the way you like to
                                                    use our service. Some cookies are used to personalize content and present you
                                                    with a tailored experience. For example, the location could be used to give you
                                                    services and offers in your area.
                                                </p>

                                                <p class="text-justify pl-3">
                                                    <b>Analytics: </b>We collect analytics about the types of people who visit our site to
                                                    improve our service and product.
                                                </p>


                                                <p class="text-justify">
                                                    We also allow third-party partners to put their cookies on our platform
                                                </p>


                                                <h5 class="pt-3"><strong>Managing Cookies</strong></h5>

                                                <p class="text-justify">
                                                    Suppose you want to delete any cookies already stored on your computer or stop the
                                                    cookies that keep track of your browsing patterns on the platform. In that case, you
                                                    can do so by deleting your existing cookies and/or altering your browser&#39;s privacy
                                                    settings (the process you follow will differ from browser to browser).
                                                </p>

                                                <p class="text-justify">
                                                    You can find more information about <a href="www.allaboutcookies.org" target="_blank" rel="noreferrer" style="color: blue">
                                                    cookies 
                                                </a> 
                                                </p>

                                    </a-col>
                                </a-row>

                            </a-card>
                        </a-col>
                    </a-row>
                            
                        

				


			</a-col>

			
		</a-row>
		
		

		
	</div>
</template>

<script>
	import VueJwtDecode from 'vue-jwt-decode'
	import VueCountryCode from "vue-country-code-select";
	// import VueFab from 'vue-float-action-button'

	export default ({
		components: {
			VueCountryCode,
			// VueFab,
		},

		data() {
			return {
				
			}
		},
		
		methods: {

		},
	})

</script>

<style lang="scss" scoped>
	body {
		background-color: #ffffff;
	}
	#signinBtn, #signinBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
	#normal_login_password.ant-input {
		height: 50px !important;
	}

	#card {
		box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
                  0 1px 18px 0 rgba(0, 0, 0, 0.12),
                  0 3px 5px -1px rgba(0, 0, 0, 0.1);


		border-radius: 12px;
	}

	#cardCarosel {
		box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
                  0 1px 18px 0 rgba(0, 0, 0, 0.12),
                  0 3px 5px -1px rgba(0, 0, 0, 0.1);


		border-radius: 12px;
	}

	#linearGradient {
		background: linear-gradient(150.28deg, #6A45AA 68.04%, rgba(106, 69, 170, 0.831448) 80.22%, rgba(106, 69, 170, 0.727899) 98.24%, rgba(125, 80, 202, 0) 100%);
	}

	
  .slick-dots {
    position: static;
    margin-top: 50px;

    li button {
      height: 16px;
      border-radius: 4px;
      background-color: gray;
    }

}

.ant-carousel .slick-dots li, .ant-carousel .slick-dots li.slick-active button, .slick-dots > li {
	height: 16px;
      border-radius: 4px !important;
      background-color: red !important;
}

.layout-default .ant-layout-header .header-nav a.router-link-active {
	color: red; 
    font-weight: 600
}

#floatingBtn1 {
	position:fixed;
	left: 50px;
	bottom: 100px;
	background-color: #0077B5 !important;
	border-color: #0077B5 !important;
}

#floatingBtn {
	position:fixed;
	left: 50px;
	bottom: 160px;
	background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%) !important;
	border-color: #fec564 !important;
}

#floatinWhatsappBtn {
	position:fixed;
	right: 50px;
	bottom: 160px;
	background-color: #29A81A !important;
	border-color: #29A81A !important;
	color: #FFFFFF;
	border-radius: 20px;
}

#floatingBtn3 {
	position:fixed;
	right: 80px;
	bottom: 160px;
	background-color: #29A81A !important;
	border-color: #29A81A !important;
	color: #FFFFFF;
	border-radius: 20px;
}
	
</style>
